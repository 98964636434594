import anime from 'animejs/lib/anime.es.js';
import 'waypoints/lib/noframework.waypoints';
export var drawingSVG = function drawingSVG() {
  var allDrawingElements = document.querySelectorAll('.svg-drawing');
  allDrawingElements.forEach(function (item) {
    new Waypoint({
      element: item,
      handler: function handler(direction) {
        var svgDuration = null;
        if (this.element.classList.contains('svg-drawing--slow')) {
          svgDuration = 7000;
        } else {
          svgDuration = 2000;
        }
        anime({
          targets: this.element.querySelectorAll('path'),
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: svgDuration
        });
        this.destroy();
      },
      triggerOnce: true,
      offset: '100%'
    });
  });
};
export var blowMainSVG = function blowMainSVG() {
  var mainSvgMorph = anime({
    targets: '.blob-svg .blob-svg-path',
    d: [{
      value: 'M1394.9,97.2c51.6,88.2-36.6,129.6-209.8,331.8c-90.4,105.6-72.1,352.5-252,516.1 c-144,130.9-361.6-39.3-507.1-231c-128.4-169.2-48-376.9,186-540.1C869.8-5.6,1327.6-17.9,1394.9,97.2L1394.9,97.2z'
    }, {
      value: 'M1394.9,97.2c51.6,88.2-36.6,129.6-209.8,331.8c-90.4,105.6-45.1,235.5-225,399 c-144,130.9-388.6,77.7-534.1-114c-128.4-169.2,0-340.9,234-504.1C917.8,30.4,1327.6-17.9,1394.9,97.2L1394.9,97.2z'
    }, {
      value: 'M1394.9,97.2c51.6,88.2-131.9,102.1-305,304.3c-90.4,105.6,72.9,213.8-107,377.4 c-144,130.9-444.4,145.2-589.9-46.6c-128.4-169.2,12-382.6,246-545.7C896.7,7,1327.6-17.9,1394.9,97.2L1394.9,97.2z'
    }],
    easing: 'easeInOutSine',
    duration: 10000,
    direction: 'infinite alternate',
    loop: true
  });
};