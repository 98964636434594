import AOS from 'aos';
import SweetScroll from 'sweet-scroll';
import { globalSlider, famousSlider } from './modules/sliders';
import { initHamburgerMenu } from './modules/menu';
import { drawingSVG, blowMainSVG } from './modules/animations';
import { organicMorphing } from './modules/organic-morph';
import { initVideo } from './modules/video';
import { initCookies } from './modules/cookies';
import { fadeOutEffect } from './modules/preloader';

// import { instagramInstance } from './modules/instagram';

var page = document.querySelector('body');

// Fix 100vh header mobile
var fixHeaderMobile = function fixHeaderMobile() {
  var mediaQuery = window.matchMedia('(max-width: 992px)');
  if (mediaQuery.matches) {
    var nav = document.querySelector('.mobile-menu');
    var header = document.querySelector('#content');
    header.style.paddingTop = "".concat(nav.clientHeight, "px");
  }
};
document.addEventListener('DOMContentLoaded', function () {
  AOS.init({
    once: true
  });
  fadeOutEffect();
  fixHeaderMobile();
  initHamburgerMenu();
  blowMainSVG();
  drawingSVG();
  organicMorphing();
  globalSlider();
  famousSlider();
  initVideo();
  initCookies();
  // instagramInstance();

  var scroller = new SweetScroll({
    easing: 'easeOutSine'
  });
  var hash = window.location.hash;
  var needsInitialScroll = document.getElementById(hash.substr(1)) != null;
  if (needsInitialScroll) {
    window.location.hash = '';
  }
  window.addEventListener('load', function () {
    if (needsInitialScroll) {
      if (page.classList.contains('page-template-team')) {
        setTimeout(function () {
          scroller.to(hash, {
            updateURL: 'replace',
            duration: 1000
          });
        }, 1500);
      } else {
        setTimeout(function () {
          scroller.to(hash, {
            updateURL: 'replace',
            duration: 4000
          });
        }, 1000);
      }
    }
  }, false);
});