export var initHamburgerMenu = function initHamburgerMenu() {
  /* Hamburger */
  var hamburgerEl = document.querySelector('.hamburger');
  var mainNav = document.querySelector('.nav');
  var topbar = document.querySelector('.mobile-menu');
  var activeMenu = function activeMenu() {
    hamburgerEl.classList.toggle('is-active');
    mainNav.classList.toggle('is-active');
    topbar.classList.toggle('is-active');
  };
  hamburgerEl.addEventListener('click', function () {
    activeMenu();
  });
};