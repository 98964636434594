import Swiper from 'swiper/swiper-bundle.min.js';
export var globalSlider = function globalSlider() {
  var swiper = new Swiper('.swiper-homepage', {
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    speed: 800,
    autoplay: {
      delay: 8500,
      disableOnInteraction: false
    },
    pagination: {
      el: '.header-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.header-button-next',
      prevEl: '.header-button-prev'
    }
  });
};
export var famousSlider = function famousSlider() {
  var swiper = new Swiper('.famous__swiper', {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    speed: 800,
    autoplay: {
      delay: 8500,
      disableOnInteraction: false
    },
    pagination: {
      el: '.famous-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.famous-button-next',
      prevEl: '.famous-button-prev'
    }
  });
};